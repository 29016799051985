import React from 'react';

function Home() {
  return (
    <div className="relative home-land" style={{ height: "75vh", overflow: "hidden" }}>
      <img 
        src="img/img/chilika-lake1.jpg" 
        alt="banner" 
        className="w-full h-full object-cover" // Changed object-fit to object-cover
      />
      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 p-4"> {/* Added padding for mobile */}
        <p className="land-text text-white text-lg sm:text-2xl font-serif text-center">
          "Transforming India's 
          Water Bodies through 
          Innovative Robotics and Sustainable Solutions"
        </p>
      </div>
    </div>
  );
}

export default Home;
