import React from 'react'

const DedicatedTeam = () => {



    return (
        <>
            <div className="w-full max-w-screen-xl mx-auto text-center py-20 px-4">
                <h3 className="text-base md:text-lg text-sky-700 font-bold">
                    ~~ Our Best Dedicated Team ~~
                </h3>
                <h2 className="text-2xl md:text-4xl font-bold mt-5 text-blue-950">
                    Mission is about people, not projects
                </h2>
                <p className="w-full md:w-1/2 mx-auto mt-5 text-sm md:text-base">
                    Expert Solutions for Sustainable Water Management. Transforming Aquaculture with Innovative Technology.
                </p>
            </div>
            <div className='w-full max-w-[1000px] mx-auto text-center'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-8 mt-14'>
                    {/* First Card */}
                    <div className='relative overflow-hidden team-card cursor-pointer group'>
                        <img
                            src='img/img/CEO.jpeg'
                            alt='CEOimg'
                            className='w-full h-auto object-cover '
                        />
                        <div
                            className='absolute bottom-0 left-0 right-0 p-8 card-overlay space-y-4 text-left transition-transform duration-500 ease-in-out transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100'
                            style={{ background: 'rgba(25, 124, 199, 0.9)' }}
                        >
                            <h2 className='text-2xl md:text-3xl font-semibold text-blue-950'>SALOMI DABRAL</h2>
                            <p>
                                I am a sustainability professional with more than 10 years of experience in the domain of
                                urban water-sanitation issues in the area of community-led developments, effectively integrating
                                with Environment Health, Infrastructure, and Education Pillars.
                            </p>
                            <p>
                                <span>Phone:</span> +91 9599530188
                            </p>
                            <p>
                                <span>Email:</span> salomi@vertoxlabs.com
                            </p>
                        </div>
                    </div>

                    {/* Second Card */}
                    <div className='relative overflow-hidden team-card cursor-pointer group'>
                        <img
                            src='img/img/coo.jpeg'
                            alt='COOimg'
                            className='w-full h-auto object-cover'
                        />
                        <div
                            className='absolute bottom-0 left-0 right-0 p-8 card-overlay space-y-4 text-left transition-transform duration-500 ease-in-out transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100'
                            style={{ background: 'rgba(25, 124, 199, 0.9)' }}
                        >
                            <h2 className='text-2xl md:text-3xl font-semibold text-blue-950'>SHEETAL PRIYADARSINI</h2>
                            <p>
                                I am a seasoned sustainability professional with over a decade of experience tackling urban water
                                and sanitation challenges. My work thrives at the intersection of community-led initiatives and
                                the integration of environmental health, infrastructure, and education, driving impactful,
                                sustainable change.
                            </p>
                            <p>
                                <span>Phone:</span> +91 7377371987
                            </p>
                            <p>
                                <span>Email:</span> admin@vertoxlabs.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default DedicatedTeam
