import React from 'react'

const Footer = () => {
    return (
        <div className='pt-14 pb-6 px-4' style={{ backgroundColor: '#281e4a' }}>
            <div className='max-w-7xl w-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-8'>
                <div className='w-full'>
                    <img src=".\Picture1.jpg" alt="logo" className='w-20 rounded-full shadow-sm' />
                    <h3 className='text-md text-white font-bold mt-4'>
                        VertoX Labs provides cutting-edge robotic and data solutions to enhance water quality management and sustainability.
                    </h3>
                    <div className='flex gap-3 mt-8 text-lg'>
                        <span className='bg-white p-2 px-3 text-xl text-sky-600 rounded-md hover:bg-sky-600 hover:text-white cursor-pointer transition delay-150 ease-in-out'>
                            <i className="fa-brands fa-facebook"></i>
                        </span>
                        <span className='bg-white p-2 px-3 text-xl text-sky-600 rounded-md hover:bg-sky-600 hover:text-white cursor-pointer transition delay-150 ease-in-out'>
                            <i className="fa-brands fa-twitter"></i>
                        </span>
                        <span className='bg-white p-2 px-3 text-xl text-sky-600 rounded-md hover:bg-sky-600 hover:text-white cursor-pointer transition delay-150 ease-in-out'>
                            <i className="fa-brands fa-linkedin"></i>
                        </span>
                        <span className='bg-white p-2 px-3 text-xl text-sky-600 rounded-md hover:bg-sky-600 hover:text-white cursor-pointer transition delay-150 ease-in-out'>
                            <i className="fa-brands fa-youtube"></i>
                        </span>
                    </div>
                </div>
                <div className='w-full text-white'>
                    <h2 className="relative text-xl font-bold mb-6">
                        Our Services
                        <span className="absolute left-0 bottom-[-4px] h-[4px] w-16 bg-yellow-500 rounded-[10px]"></span>
                    </h2>
                    <ul className='flex flex-col gap-4'>
                        <li>Rejuvenation Of Biodiversity</li>
                        <li>Carbon Sequestration</li>
                        <li>Increased Fisheries</li>
                        <li>Better Livelihood</li>
                    </ul>
                </div>
                <div className='space-y-3 text-white'>
                    <h2 className="relative text-xl font-bold mb-6">
                        Contact Info
                        <span className="absolute left-0 bottom-[-4px] h-[4px] w-16 bg-yellow-500 rounded-[10px]"></span>
                    </h2>
                    <span className='flex gap-3'>
                        <i className="fa-solid fa-location-dot text-lg"></i>
                        <p>
                            304A, TBI 2, KIIT CAMPUS 11
                            PATIA, BHUBANESWAR
                            ODISHA – 751024
                        </p>
                    </span>
                    <span className='flex gap-3'>
                        <i className="fa-solid fa-envelope-open-text"></i>
                        <p>admin@vertoxlabs.com</p>
                    </span>
                    <span className='flex gap-3'>
                        <i className="fa-solid fa-phone-volume"></i>
                        <p>+91 9777171033</p>
                    </span>
                    <span className='flex gap-3'>
                        <i className="fa-solid fa-clock"></i>
                        <p>Office: 09:00 am - 10.00 pm</p>
                    </span>
                </div>
            </div>
            <div className='text-white text-center my-4 mt-14'>
                <p className='font-semibold'>
                    Copyright &copy; 2024, Vertoxlabs All Rights Reserved
                </p>
            </div>
        </div>
    )
}

export default Footer;