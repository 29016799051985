import React from 'react';

const AboutUs = () => {
    return (
        <div className="my-10 pt-10 overflow-hidden">
            <div className="flex flex-col sm:flex-row max-w-full sm:max-w-[640px] md:max-w-[960px] lg:max-w-[1260px] mx-auto gap-4 sm:gap-6 md:gap-8 lg:gap-8 px-4 sm:px-0"> 
                <div className="w-full sm:w-1/2 shadow-xl p-2 about-us h-fit md:mt-16 " >
                    <img
                        src="img/img/new_banner.jpg"
                        alt="About Us"
                        className="w-full  rounded-md shadow-lg" 
                    />
                </div>

                <div className="w-full sm:w-1/2 space-y-6 about-us">
                    <h3 className="text-sky-700 text-xl font-bold">About Us ~</h3>
                    <h2 className="text-4xl font-bold text-blue-950">
                        Ensuring Clean Water for a Sustainable Future
                    </h2>
                    <p>
                        Aspire to be a market leader in water body management in the direction of aquafarming by focusing on employment generation and creation of wealth by leveraging developed technology utilizing surface water reserves.
                    </p>
                    <p>
                        Develop sustainable technology and processes for aquaculture farming by solving critical water and disease issues, which will benefit traditional to institutional farming.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {[
                            {
                                title: 'TEAM',
                                description: 'Domain expert team of enthusiast professionals',
                            },
                            {
                                title: 'SOLUTION',
                                description: 'Offers turnkey solutions',
                            },
                            {
                                title: 'R&D',
                                description: 'Groundbreaking research in multidisciplinary fields',
                            },
                        ].map((item, index) => (
                            <div key={index} className="flex flex-col items-start">
                                <span className="flex items-center text-xl">
                                    <i className="fa-regular fa-circle-right text-sky-600 text-xl"></i>
                                    <h3 className="ml-2">{item.title}</h3>
                                </span>
                                <p className="text-sm text-gray-600">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
