import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'; // Icon for hamburger menu
 
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
 
  // Function to toggle the menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
 
  return (
    <nav className="bg-white shadow-md fixed w-full z-10">
      <div className="container mx-auto px-4 flex justify-between items-center h-28">
        {/* Logo Section */}
        <div>
          <img
            src="brand.png" // Replace with the correct path to your image
            alt="Logo"
            className="h-24 p-4w-auto" // Adjust the height/width as per your design
          />
        </div>
 
        {/* Hamburger icon for mobile view */}
        <div className="md:hidden" onClick={toggleMenu}>
          {isOpen ? (
            <AiOutlineClose className="text-3xl text-gray-800" />
          ) : (
            <AiOutlineMenu className="text-3xl text-gray-800" />
          )}
        </div>
 
        {/* Menu Items */}
        <ul
          className={`md:flex md:items-center md:space-x-6 absolute md:relative md:bg-transparent bg-white/90 md:w-auto w-1/2 right-0 md:left-auto md:top-0 top-16 transition-transform ${
            isOpen ? 'block' : 'hidden'
          }`}
        >
          <li className="py-2 px-4 md:py-0 text-xl font-bold">
            <ScrollLink
              to="home"
              smooth={true}
              duration={500}
              className="relative block text-gray-800  cursor-pointer after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-0 after:h-[2px] after:bg-yellow-500 after:transition-all after:duration-500 hover:after:w-full"
              onClick={toggleMenu} // Close menu on click
            >
              Home
            </ScrollLink>
          </li>
          <li className="py-2 px-4 md:py-0 font-bold text-xl">
            <ScrollLink
              to="about"
              smooth={true}
              duration={500}
              className="relative block text-gray-800  cursor-pointer after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-0 after:h-[2px] after:bg-yellow-500 after:transition-all after:duration-500 hover:after:w-full"
              onClick={toggleMenu}
            >
              About Us
            </ScrollLink>
          </li>
          <li className="py-2 px-4 md:py-0 text-xl font-bold">
            <ScrollLink
              to="wework"
              smooth={true}
              duration={500}
              className="relative block text-gray-800  cursor-pointer after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-0 after:h-[2px] after:bg-yellow-500 after:transition-all after:duration-500 hover:after:w-full"
              onClick={toggleMenu}
            >
              Services
            </ScrollLink>
          </li>
          <li className="py-2 px-4 md:py-0 text-xl font-bold">
            <ScrollLink
              to="product"
              smooth={true}
              duration={500}
              className="relative block text-gray-800  cursor-pointer after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-0 after:h-[2px] after:bg-yellow-500 after:transition-all after:duration-500 hover:after:w-full"
              onClick={toggleMenu}
            >
              Product
            </ScrollLink>
          </li>
          <li className="py-2 px-4 md:py-0 text-xl font-bold">
            <ScrollLink
              to="contact"
              smooth={true}
              duration={500}
              className="relative block text-gray-800  cursor-pointer after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-0 after:h-[2px] after:bg-yellow-500 after:transition-all after:duration-500 hover:after:w-full"
              onClick={toggleMenu}
            >
              Contact Us
            </ScrollLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};
 
export default Navbar;