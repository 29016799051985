export const BestServiceData = [
    {
        title:"Carbon Credits and Farmer Incentives",
        desc:"We generate carbon and water security credits during restoration, which are sold to carbon-intensive industries. Our blockchain framework ensures marginal farmers benefit, promoting sustainable practices ",
        img:"img/IMG_5577.jpg"
    },
    {
        title:"Rejuvenating Water Bodies with Tech",
        desc:"We fully restore water bodies' biodiversity using advanced robotic technology, AI, and IoT, guided by CPCB standards to ensure sustainable and aesthetic ecosystems",
        img:"img/IMG_2373.jpg"
    },
    {
        title:"Innovative Water Quality Solutions",
        desc:"Vertox Labs is transforming water quality in lakes and reservoirs by integrating vertical agriculture and aquaculture. Our socioecological model not only restores ecosystems but also boosts profitability for aquafarmers",
        img:"img/WhatsApp Image 2023-07-07 at 09.12.51 (2) (1).jpeg"
    },
]