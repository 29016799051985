import React,{useState} from 'react'
import axios from 'axios';


const FormData = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phno: '',
        companyname: '',
        message: '',
      });

      const [loading, setLoading] = useState(false); // to manage loading state
      const [error, setError] = useState('');
      const [success, setSuccess] = useState(false);
      const[message,setMessage]=useState();
      const apiUrl = "aqrabg.bc-pl.com/";
      console.log(apiUrl);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess(false);
    
        try {
          const response = await axios.post(`${apiUrl}/contact/`, formData);
          console.log(response.data.message);
          
          if (response.status === 201 && response.data.message) {
            console.log("Form submitted successfully");
            setSuccess(true);
            setMessage(response.data.message);
            setFormData({
              name: '',
              email: '',
              phno: '',
              companyname: '',
              message: '',
            });
            e.target.reset();
          } else {
            setError('Unexpected response from the server.');
            console.error('Error:', error);
          }
        } catch (error) {
          console.error('Error details:', error.response ? error.response.data : error.message);
          if (error.response) {
            setError(`Server Error: ${error.response.status} - ${error.response.statusText}`);
          } else if (error.request) {
            setError('No response from the server. Please check your network connection.');
          } else {
            setError('An unexpected error occurred. Please try again later.');
          }
        } finally {
          setLoading(false);
        }
      };

  return (
    <div className="space-y-3 md:space-y-6 my-4 flex flex-col justify-center items-center sm:max-w-[40rem] md:max-w-[50rem] mx-auto px-3 md:px-6 bg-white rounded-lg shadow-lg py-8">
    <h2 className="font-semibold text-3xl text-gray-800">Connect with us</h2>
    <p className="text-center text-gray-600">
      Share your details for customer assistance, business &amp; partnership
      enquiries. Our team will get back to you shortly.
    </p>
    <form onSubmit={handleSubmit} className="px-2 my-2 md:px-12 w-full flex flex-col justify-center space-y-4">
      <div style={{ width: '100%' }}>
        <input
          className="input border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-transparent"
          type="text"
          name="name"
          placeholder="Name *"
          required
          value={formData.name}
          onChange={handleChange}
          style={{ width: '100%' }}
        />
      </div>
  
      <div style={{ width: '100%' }}>
        <input
          className="input border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-transparent"
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          style={{ width: '100%' }}
        />
      </div>
  
      <div style={{ width: '100%' }}>
        <input
          className="input border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-transparent"
          type="text"
          name="phno"
          placeholder="Mobile Number *"
          value={formData.phno}
          onChange={handleChange}
          required
          style={{ width: '100%' }}
        />
      </div>
  
      <div style={{ width: '100%' }}>
        <input
          className="input border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-transparent"
          type="text"
          name="companyname"
          placeholder="Company Name"
          value={formData.companyname}
          onChange={handleChange}
          style={{ width: '100%' }}
        />
      </div>
  
      <div style={{ width: '100%' }}>
        <textarea
          className="input border border-gray-300 rounded-md py-2 px-4 h-32 focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-transparent"
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          style={{ width: '100%' }}
        ></textarea>
      </div>
  
      <button
        className="bg-sky-500 text-xl text-white rounded-md py-3 px-7 font-semibold mx-auto hover:bg-sky-600 transition duration-300 ease-in-out"
        type="submit"
      >
        {loading ? 'Sending...' : 'Send'}
      </button>
    </form>
    {error && <p className="text-red-500">{error}</p>}
    {success && <p className="text-green-500 text-2xl font-serif italic ">{message}</p>}
  </div>
  )
}

export default FormData
