import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Body/Home';
import AboutUs from './components/Body/AboutUs';
import WeWork from './components/Body/WeWork';
import DedicatedTeam from './components/Body/DedicatedTeam';
import Product from './components/Body/Product';
import Testimonial from './components/Body/Testimonial';
import FormData from './components/Body/FormData';
import Footer from './components/Body/Footer';
 
const App = () => {
  return (
    <>
      <Navbar />
      <section id="home">
        <Home />
      </section>
      <section id="about">
        <AboutUs />
      </section>
      <section id="wework">
        <WeWork />
      </section>
      <section id="dedicated-team">
        <DedicatedTeam />
      </section>
      <section id="product">
        <Product />
      </section>
      <section id="testimonial">
        <Testimonial />
      </section>
      <section id="contact">
        <FormData />
      </section>
      <Footer />
    </>
  );
};
 
export default App;